<template>
  <div
    :class="[
      positionClass,
      filtersOverlayClasses,
      { 'spinner-top': spinnerPosition === 'top' }
    ]">
    <div>
      <loading-spinner class="mx-auto" />
      <transition
        name="text"
        appear>
        <p
          v-if="text"
          class="typeset-9 text-ink mt-3 mb-0">
          {{ text }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
  name: "Overlay",
  components: {
    LoadingSpinner
  },
  props: {
    absolutelyPositioned: {
      type: Boolean,
      default: false
    },
    isDrawerOverlay: {
      type: Boolean,
      default: false
    },
    spinnerPosition: {
      type: String,
      default: "center",
      validator: value => {
        return ["center", "top"].indexOf(value) !== -1;
      }
    },
    text: {
      type: String,
      default: null
    }
  },
  computed: {
    positionClass() {
      return this.absolutelyPositioned ? "absolute" : "fixed";
    },
    filtersOverlayClasses() {
      if (this.isDrawerOverlay) {
        return "spinner-overlay-filters";
      }
      return "spinner-overlay";
    }
  }
};
</script>

<style lang="scss" scoped>
.text-enter-from,
.text-leave-to {
  opacity: 0;
  transform: translate3d(0, 8px, 0);
}

.text-enter-active,
.text-leave-active {
  transition: all 0.2s ease-out;
}

.text-enter-to,
.text-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
